
  export const style={
    inpuStyle:{
      colorInput:"#636466"
    },
    url:{
      urlClient:"https://voepasscomunica.prd.appnoz.com.br/",
      urlClientYouseed:"https://admyouseed.bancosemear.com.br/"
    },
    fileUrl:{
      logo:"https://timcoo-geral.s3.amazonaws.com/Logos/primary_logo_voepass.png",
      secondaryLogonLogo: "null",
    },
    variables:{
       customMenuItem: true,
       autoRegister: false,
       recaptchaVersion : "V1"
    },
    CurrencyName:{
      name:"Ponto/Pontos"
    },
    teamName:{
      name:"VOEPASS Comunica"
    },
    hasToken: false,
    hostHeader: "VOEPASS"
  }
